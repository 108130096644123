import React from "react";

import clsx from "clsx";
import Heading from "components/heading";
import { getAbbrSuffix, getFormattedValue } from "components/helpers";
import { useInView } from "react-intersection-observer";
import { useCountUp } from "use-count-up";

import HeroSection from "../../hero-section";
import { fallbackData, SiteData } from "../constants";

const title = "Proven Platform";

export type ProvenPlatformProps = { data: SiteData };

const ProvenPlatform = ({ data }: ProvenPlatformProps) => {
  const { inView, ref } = useInView({ threshold: 0.6 });
  const useData = data || fallbackData;
  const revenueFormatted = getFormattedValue(useData.revenueToDate);
  const agreementsFormatted = getFormattedValue(useData.agreementsToDate);
  const { value: revenueToDate } = useCountUp({
    isCounting: inView,
    end: parseFloat(revenueFormatted),
    duration: 3,
  });
  const { value: agreementsToDate } = useCountUp({
    isCounting: inView,
    end: parseFloat(agreementsFormatted),
    duration: 3,
  });

  return (
    <div className="bg-gradient-to-b from-white to-primary">
      <HeroSection title={title} className="flex-col md:flex-row">
        <HeroSection.Content className="w-full md:p-8 lg:px-10 xl:px-20 flex-1">
          <HeroSection.Header className="md:items-start">
            <Heading tag="h2">{title}</Heading>
          </HeroSection.Header>
          <HeroSection.Description className="text-gray-700">
            Enerflo powers the Solar Process for partners across the country & constantly invests in technology &
            infrastructure so you don’t have to. The numbers speak for themselves.
          </HeroSection.Description>
        </HeroSection.Content>

        <div className="md:pr-6 flex-1">
          <figure
            className={clsx(
              "flex flex-col space-y-4 divide-y-2 divide-gray-200 mt-6 p-4 bg-white rounded-xl shadow-lg",
              "xs:divide-x-2 xs:divide-y-0 xs:space-y-0 xs:space-x-4 xs:flex-row xs:items-center",
              "md:mt-0 lg:p-6"
            )}
            ref={ref}
          >
            <div className="flex flex-1 flex-col justify-center items-center p-4 lg:p-8 space-y-2 lg:min-w-[220px]">
              <p className="text-4xl md:text-5xl lg:text-6xl">
                ${revenueToDate}
                {getAbbrSuffix(revenueFormatted)}
              </p>
              <p className="text-sm md:text-base whitespace-nowrap">Solar Sold on Enerflo</p>
            </div>
            <div className="flex flex-1 flex-col justify-center items-center p-4 lg:p-8 space-y-2 lg:min-w-[220px]">
              <p className="text-4xl md:text-5xl lg:text-6xl">
                {agreementsToDate}
                {getAbbrSuffix(agreementsFormatted)}
              </p>
              <p className="text-sm md:text-base whitespace-nowrap">Agreements Signed</p>
            </div>
          </figure>
        </div>
      </HeroSection>
    </div>
  );
};

export default ProvenPlatform;
